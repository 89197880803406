import React, { useState } from 'react';
import withFormikField from '../../../common/utils/withFormikField';
import { MonetaryScalesInputProps } from './MonetaryPrecisionScalesInputTypes';

import { useCompanySetting } from '../../../common/hooks/useCompanySettings';
import { NumericFormat } from 'react-number-format';

import { ControlledInput } from './ControlledInput';
import { MONETARY_VAT_NET_VALID_CHARACTERS_REGEXP } from '../MonetaryPrecisionScalesHelper';

export const MonetaryPrecisionScalesInput: React.FC<MonetaryScalesInputProps> = (props) => {
    const { type, forceAlwaysShowDecimal, ...inputProps } = props;
    const { Value: decimalScale } = useCompanySetting('MonetaryNetVatTotalPrecision');
    const [showFixedDecimalScale, setShowFixedDecimalScale] = useState(true);

    const handleFocusInput: React.FocusEventHandler<HTMLInputElement> = (e) => {
        inputProps.onChange(e);
        if (!forceAlwaysShowDecimal) {
            setShowFixedDecimalScale(false);
        }
    };

    const handleBlurInput: React.FocusEventHandler<HTMLInputElement> = (e) => {
        inputProps.onBlur(e);
        if (!forceAlwaysShowDecimal) {
            setShowFixedDecimalScale(true);
        }
    };

    return (
        <NumericFormat
            {...inputProps}
            customInput={ControlledInput}
            inputType={type}
            fixedDecimalScale={showFixedDecimalScale}
            decimalScale={props.decimalScale || Number(decimalScale)}
            validCharacters={MONETARY_VAT_NET_VALID_CHARACTERS_REGEXP}
            onFocus={handleFocusInput}
            onBlur={handleBlurInput}
        />
    );
};

// Resolve input props for using as Formik Field component
export const MonetaryPrecisionScalesField = withFormikField(MonetaryPrecisionScalesInput);
