import React, { useState, useEffect, useRef } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { assign, isEmpty, isEqual } from 'lodash-es';
import * as Yup from 'yup';

import FormikField from '../../../../../common/utils/FormikField';
import { formatDate } from '../../../../../common/utils/formatters';
import SumsDifferentTooltip from './SumsDifferentTooltip';
import { Button, ButtonType, ButtonIconPlacement } from '../../../../../components/Buttons/Button';
import { TextInput, TextInputField, TextInputType } from '../../../../../components/TextInput/TextInput';
import { InvoiceDTO, InvoiceDataResponseDTO, SupplierDTO, Currency } from '../../../../../services/types/ApiTypes';
import { createDataId } from '../../../../../common/utils/dataId';
import { ICONS } from '../../../../../components/Icon/Icon';
import createFieldNameConstants from '../../../../../common/utils/fieldNames';
import { ALPHANUMERIC_EXTENDED_REGEXP } from '../../../../../common/utils/validators';
import { CalendarDatePickerField } from '../../../../../components/CalendarDatePicker/CalendarDatePicker';
import { InvoiceEditValues } from './InvoiveHeaderTypes';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import api from '../../../../../services/ApiServices';
import { areTwoNumsEqualWithPrecision, filterCurrenciesByName, typesList } from '../utils';
import { TypeaheadAsync, TypeaheadAsyncField, TypeaheadItem } from '../../../../../components/Typeahead/TypeaheadAsync';
import PropertyListItem from '../../../../../components/PropertyListItem/PropertyListItem';
import { Role, isAuthorized } from '../../../../../common/user/userPermissionUtil';
import usePreventUnload from '../../../../../common/hooks/usePreventUnload';
import { LoadableData } from '../../../../../common/utils/LoadableData';
import { getCurrencyName } from '../../../../../common/user/userUtils';
import { Typeahead } from '../../../../../components/Typeahead/Typeahead';
import { WithTranslateFormErrors } from '../../../../../components/WithTranslateFormErrors/WithTranslateFormErrors';
import AddNewSupplierButton from './AddSupplierButton';
import { MonetaryPrecisionScalesField } from '../../../../../components/MonetaryPrecisionScales/MonetaryPrecisionScalesInput';

export const dataId = 'invoice-header.basic-info-edit';

interface Props {
    invoice: InvoiceDataResponseDTO & InvoiceDTO;
    cancelEditting: () => void;
    t: TFunction;
    saveInvoice: (i: InvoiceDTO & InvoiceDataResponseDTO, onlySupplier?: boolean) => void;
    showAddOrEditSupplierModal: (s: SupplierDTO) => void;
    updateSupplierId: (s: string, i: string) => Promise<SupplierDTO>;
    addSupplierLoadable: LoadableData<SupplierDTO, SupplierDTO>;
    updateSupplierLoadable: LoadableData<SupplierDTO, SupplierDTO>;
}

const initialInvoiceValues: InvoiceEditValues = {
    AccountingDate: null,
    Beneficiary: null,
    ContractNumber: null,
    Currency: null,
    Description: null,
    DeleteTransactionRowsDuringSave: false,
    DueDate: null,
    InvoiceDate: null,
    IsCredit: null,
    Number: null,
    PayToAccount: null,
    ReferenceNumber: null,
    SumWithoutVat: null,
    Supplier: null,
    TotalAmountWithVat: null,
    Vat: null,
};

const {
    AccountingDate: AccountingDateField,
    Beneficiary: BeneficiaryField,
    ContractNumber: ContractNumberField,
    Currency: CurrencyField,
    Description: DescriptionField,
    DueDate: DueDateField,
    InvoiceDate: InvoiceDateField,
    IsCredit: IsCreditField,
    Number: InvoiceNumberField,
    PayToAccount: PayToAccountField,
    ReferenceNumber: ReferenceNumberField,
    SumWithoutVat: SumWithoutVatField,
    Supplier: SupplierField,
    TotalAmountWithVat: TotalAmountWithVatField,
    Vat: VatField,
} = createFieldNameConstants(initialInvoiceValues);

const BasicInfoEditMode = ({ invoice, cancelEditting, t, saveInvoice: saveUpdatedInvoice, showAddOrEditSupplierModal, updateSupplierId, addSupplierLoadable, updateSupplierLoadable }: Props) => {
    const [invoiceFormValues, setInvoiceFormValues] = useState<InvoiceEditValues>({} as InvoiceEditValues);
    const [currencyList, setCurrencyList] = useState<Currency[]>(null);

    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

    const formRef = useRef<Formik<InvoiceEditValues>>();

    const [selectedType, setSelectedType] = useState<TypeaheadItem<boolean>>({
        text: t(typesList[invoice?.IsCredit ? 1 : 0].text),
        value: invoice?.IsCredit,
    });
    const [sumsDiffer, setSumsDiffer] = useState<boolean>();

    // TODO: implement app inner route watcher to prevent loosing entered data
    usePreventUnload(isFormDirty);

    const validationSchema = Yup.object<InvoiceEditValues>().shape({
        [SupplierField]: Yup.object<TypeaheadItem<SupplierDTO>>()
            .required(t('view.general.mandatoryField'))
            .typeError(t('view.general.mandatoryField')),
        [InvoiceNumberField]: Yup.string()
            .nullable()
            .required(t('view.general.mandatoryField')),
        [InvoiceDateField]: Yup.date()
            .required(t('view.general.mandatoryField'))
            .typeError(t('view.general.mandatoryField')),
        [AccountingDateField]: Yup.date()
            .required(t('view.general.mandatoryField'))
            .typeError(t('view.general.mandatoryField')),
        [DueDateField]: Yup.date()
            .min(Yup.ref(InvoiceDateField), t('component.additionalInfo.error.validationDueDate'))
            .required(t('view.general.mandatoryField'))
            .typeError(t('view.general.mandatoryField')),
        [PayToAccountField]: Yup.string()
            .nullable()
            .matches(ALPHANUMERIC_EXTENDED_REGEXP, t('component.additionalInfo.error.validationIban')),
        // .test('iban-test', t('component.additionalInfo.error.validationIban'), (val) => (val ? IBAN.isValid(val) : true)),
        [CurrencyField]: Yup.object<Currency>()
            .required(t('view.general.mandatoryField'))
            .typeError(t('view.general.mandatoryField')),
        [SumWithoutVatField]: Yup.number()
            .typeError(t('component.additionalInfo.error.validationSumWithoutVat'))
            .required(t('view.general.mandatoryField')),
        [VatField]: Yup.number()
            .typeError(t('component.additionalInfo.error.validationVatSum'))
            .required(t('view.general.mandatoryField')),
        [TotalAmountWithVatField]: Yup.number()
            .typeError(t('component.additionalInfo.error.validationInvoiceTotalSum'))
            .required(t('view.general.mandatoryField')),
        [BeneficiaryField]: Yup.object<TypeaheadItem<string>>()
            .required(t('view.general.mandatoryField'))
            .typeError(t('view.general.mandatoryField')),
    });

    const checkSumsAreDifferent = (values: InvoiceEditValues) => {
        const { InvoiceRowsPrice, InvoiceRowsCount, InvoiceRowsVAT, InvoiceRowsTotal } = invoice;
        const { SumWithoutVat, TotalAmountWithVat, Vat } = values;
        let differ = false;
        if (
            InvoiceRowsCount > 0 &&
            (!areTwoNumsEqualWithPrecision(SumWithoutVat, InvoiceRowsPrice) ||
                !areTwoNumsEqualWithPrecision(Vat, InvoiceRowsVAT) ||
                !areTwoNumsEqualWithPrecision(TotalAmountWithVat, InvoiceRowsTotal))
        ) {
            differ = true;
        }
        setSumsDiffer(differ);
        return differ;
    };

    useEffect(() => {
        if (invoice) {
            const newInvoiceValues: InvoiceEditValues = { ...initialInvoiceValues };
            Object.keys(initialInvoiceValues).map((key) => (newInvoiceValues[key] = invoice[key]));
            newInvoiceValues.Supplier = invoice.Supplier ? { text: invoice?.Supplier?.Name, value: invoice?.Supplier } : null;
            newInvoiceValues.Beneficiary = invoice.Beneficiary ? { text: invoice?.Beneficiary, value: invoice?.Beneficiary } : null;
            newInvoiceValues.Currency = { text: `${invoice?.Currency} - ${getCurrencyName(invoice?.Currency)}`, value: invoice?.Currency };
            if (!isEqual(invoiceFormValues, newInvoiceValues) && isFormDirty) {
                // prevent showing warning message when a new invoice is loaded and user wants to navigates away before making changes
                setIsFormDirty(false);
            }
            setInvoiceFormValues(newInvoiceValues);
            checkSumsAreDifferent(newInvoiceValues);
        }
    }, [invoice]);

    const loadSupplier = (supplier: SupplierDTO) => {
        if (supplier) {
            setInvoiceFormValues({
                ...formRef?.current?.state?.values,
                Supplier: { text: supplier.Name, value: supplier },
                PayToAccount: supplier.BankAccount,
                Beneficiary: supplier?.Name ? { text: supplier.Name, value: supplier.Name } : formRef?.current?.state?.values?.Beneficiary,
            });
            setIsFormDirty(true);
        }
    };

    useEffect(() => {
        if (addSupplierLoadable?.loaded && addSupplierLoadable?.payload?.Id && !addSupplierLoadable?.payload?.IsNew && !isEmpty(invoiceFormValues)) {
            loadSupplier(addSupplierLoadable.payload);
        }
    }, [addSupplierLoadable]);

    useEffect(() => {
        if (updateSupplierLoadable?.loaded && updateSupplierLoadable?.payload?.Id && !isEmpty(invoiceFormValues)) {
            loadSupplier(updateSupplierLoadable.payload);
        }
    }, [updateSupplierLoadable]);

    const isAddOrEditSupplierEnabled = isAuthorized(Role.CanAddOrEditInvoiceSupplier);

    const mapFormValuesToInvoiceDTO = (vals: InvoiceEditValues): InvoiceDTO & InvoiceDataResponseDTO => ({
        ...invoice,
        ...vals,
        Beneficiary: vals.Beneficiary?.text,
        Currency: vals.Currency?.value,
        Supplier: null as SupplierDTO,
        // we don't need to send the whole object to update Supplier, just his Id in a separate field
        SupplierId: vals.Supplier?.value?.Id,
        SupplierName: vals.Supplier?.value?.Name,
        IsCredit: selectedType.value,
    });

    const saveInvoice = (values: InvoiceEditValues) => saveUpdatedInvoice(mapFormValuesToInvoiceDTO(values));

    const checkIsDuplicateAndSave = (values: InvoiceEditValues) => {
        if (invoice.IsNew) {
            const invoiceData = {
                InvoiceDate: values.InvoiceDate,
                TotalAmountWithVat: values.TotalAmountWithVat,
                SupplierId: values.Supplier?.value?.Id,
                Number: values.Number,
            };
            api.invoice.checkIsDuplicate(invoiceData).then(
                function(response) {
                    const result = response.data;
                    setIsDuplicate(result);
                    if (!result) {
                        saveInvoice(values);
                    }
                },
                function(data) {
                    console.log(data);
                },
            );
        } else {
            saveInvoice(values);
        }
    };

    const getSuppliersByName = async (suppName?: string): Promise<SupplierDTO[]> => {
        if (suppName) {
            const response = await api.suppliers.getSuppliersByName(suppName);
            return response.data;
        }
        const response = await api.suppliers.getSuppliers();
        return response.data.Items;
    };

    const getBeneficiaryByName = async (name?: string): Promise<SupplierDTO[]> => {
        const allBeneficiaries = await getSuppliersByName(name);
        return allBeneficiaries;
    };

    const changeSupplier = (s: TypeaheadItem<SupplierDTO>, values: InvoiceEditValues) => {
        setInvoiceFormValues({
            ...values,
            Supplier: s,
            PayToAccount: s?.value?.BankAccount || values.PayToAccount,
            Beneficiary: s?.value?.Name ? { text: s.value.Name, value: s.value.Name } : values.Beneficiary,
        });
        setIsFormDirty(true);
    };

    const checkAndUpdateSupplierId = (currentSupp: SupplierDTO, newSupp: SupplierDTO) => {
        if (!isEqual(currentSupp, newSupp) && !invoice.IsNew) {
            updateSupplierId(newSupp?.Id?.toString(), invoice?.Id?.toString());
        }
    };

    const confirmSaving = (formik: FormikProps<InvoiceEditValues>) => {
        checkAndUpdateSupplierId(invoice?.Supplier, formik?.values?.Supplier?.value);
        formik.submitForm();
    };

    const discardChanges = () => {
        if (updateSupplierLoadable?.payload) {
            if (invoice?.Supplier?.Id === updateSupplierLoadable?.payload?.Id && !isEqual(invoice?.Supplier, updateSupplierLoadable?.payload) && !invoice.IsNew) {
                saveUpdatedInvoice(
                    {
                        ...invoice,
                        Supplier: null as SupplierDTO,
                        SupplierId: updateSupplierLoadable.payload?.Id,
                        SupplierName: updateSupplierLoadable.payload?.Name,
                    },
                    true,
                );
            }
        }
        cancelEditting();
    };

    const closeTooltip = () => {
        setSumsDiffer(false);
        window.setTimeout(() => {
            setSumsDiffer(true);
        }, 100);
    };

    const getTooltipContent = (formik: FormikProps<InvoiceEditValues>) => {
        if (isDuplicate) {
            return <span>{t('views.invoice.partials.invoiceInformation.Error.DuplicateFound')}</span>;
        } else if (sumsDiffer) {
            const confirm = () => {
                formik.values.DeleteTransactionRowsDuringSave = true;
                confirmSaving(formik);
            };

            return <SumsDifferentTooltip cancelSaving={() => closeTooltip()} confirmSaving={confirm} t={t} />;
        }
        return null;
    };

    const handleSaveClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, formik: FormikProps<InvoiceEditValues>) => {
        e.stopPropagation();
        const differ = checkSumsAreDifferent(formik?.values);

        if (!differ && !isDuplicate) {
            checkAndUpdateSupplierId(invoice?.Supplier, formik?.values?.Supplier?.value);
            formik.submitForm();
        }
    };

    const getCurrenciesByName = async (name: string): Promise<Currency[]> => {
        if (!currencyList?.length) {
            const { data } = await api.invoice.getCurrencyList();
            setCurrencyList(data);
            return filterCurrenciesByName(name, data);
        } else {
            return filterCurrenciesByName(name, currencyList);
        }
    };

    return (
        <Formik onSubmit={checkIsDuplicateAndSave} ref={formRef} initialValues={invoiceFormValues} enableReinitialize={true} validationSchema={validationSchema} validateOnBlur={true}>
            {(formik: FormikProps<InvoiceEditValues>) => {
                return (
                    <WithTranslateFormErrors errors={formik.errors} setFieldTouched={formik.setFieldTouched} touched={formik.touched}>
                        <Form
                            onChange={() => {
                                setIsDuplicate(false);
                                setIsFormDirty(true);
                                checkSumsAreDifferent(formik?.values);
                            }}
                        >
                            <div className="invoice-header__actions--row">
                                <div className="invoice-header__actions invoice-header__actions--supplier">
                                    <div className="text-button-wrapper">
                                        <Button
                                            dataId={createDataId(dataId, 'button', 'add-new-supplier')}
                                            onClick={() => showAddOrEditSupplierModal(undefined)}
                                            buttonType={ButtonType.ICON_TEXT}
                                            icon={ICONS.ADD_24}
                                            iconPlacement={ButtonIconPlacement.LEFT}
                                            disabled={!isAddOrEditSupplierEnabled}
                                        >
                                            {t('views.company.detail.Add_Company')}
                                        </Button>
                                    </div>
                                    <div className="text-button-wrapper">
                                        <Button
                                            dataId={createDataId(dataId, 'editSupplierButton')}
                                            disabled={!formik.values.Supplier?.value || !isAddOrEditSupplierEnabled}
                                            buttonType={ButtonType.ICON_TEXT}
                                            icon={ICONS.EDIT_24}
                                            onClick={() => showAddOrEditSupplierModal(formik.values.Supplier?.value)}
                                            iconPlacement={ButtonIconPlacement.LEFT}
                                        >
                                            {t('component.basicInfo.editSupplier')}
                                        </Button>
                                    </div>
                                </div>
                                <div className="invoice-header__actions invoice-header__actions--save-cancel">
                                    <div className="text-button-wrapper">
                                        <Tooltip interactive={true} delay={0} content={getTooltipContent(formik)} trigger="mouseenter" hideOnClick={false} isEnabled>
                                            <Button
                                                disabled={!invoice.IsNew && !formik.dirty && !isFormDirty}
                                                buttonType={ButtonType.ICON_TEXT}
                                                dataId={createDataId(dataId, 'submitButton')}
                                                icon={ICONS.SAVE_24}
                                                onClick={(e) => handleSaveClick(e, formik)}
                                                iconPlacement={ButtonIconPlacement.LEFT}
                                            >
                                                {t('views.global.save')}
                                            </Button>
                                        </Tooltip>
                                    </div>
                                    <div className="text-button-wrapper discard">
                                        <Button
                                            onClick={discardChanges}
                                            dataId={createDataId(dataId, 'cancelEdittingButton')}
                                            buttonType={ButtonType.ICON_TEXT}
                                            icon={ICONS.CANCEL_CHANGES_24}
                                            iconPlacement={ButtonIconPlacement.LEFT}
                                        >
                                            {t('component.basicInfo.discardChanges')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="invoice-header__head">
                                <div className="invoice-header__head--left edit">
                                    <FormikField
                                        component={TypeaheadAsyncField}
                                        dataId={createDataId(dataId, 'supplierName')}
                                        toggleVisible
                                        name={SupplierField}
                                        searchOnFocus
                                        inputProps={{ label: t('component.additionalInfo.supplierName'), type: TextInputType.COMPACT }}
                                        onChange={(s: TypeaheadItem<SupplierDTO>) => changeSupplier(s, formik.values)}
                                        loadData={getSuppliersByName}
                                        itemToText={(s: SupplierDTO) => s?.Name}
                                        placeholder={t('component.additionalInfo.supplierName')}
                                        wrapperClass="fixed-height fixed-width"
                                        preItemsListElement={isAddOrEditSupplierEnabled && <AddNewSupplierButton t={t} dataId={dataId} onClick={() => showAddOrEditSupplierModal(undefined)} />}
                                    />
                                    <FormikField
                                        component={TextInputField}
                                        dataId={createDataId(dataId, 'invoiceDescription')}
                                        name={DescriptionField}
                                        textarea={true}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.description')}
                                        placeholder={t('component.additionalInfo.description')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                </div>
                                <div className="invoice-header__head--right edit">
                                    <FormikField
                                        component={TextInputField}
                                        dataId={createDataId(dataId, 'invoiceNumber')}
                                        name={InvoiceNumberField}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.invoiceNumber')}
                                        placeholder={t('component.additionalInfo.invoiceNumber')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                    <FormikField
                                        component={Typeahead}
                                        dataId={createDataId(dataId, 'isCredit')}
                                        name={IsCreditField}
                                        toggleVisible
                                        onChange={(type: TypeaheadItem<boolean>) => {
                                            setSelectedType(type);
                                            setIsFormDirty(true);
                                        }}
                                        value={selectedType}
                                        inputProps={{ label: t('view.PurchaseOrders.Type'), type: TextInputType.COMPACT }}
                                        items={typesList.map((e) => ({
                                            value: e.value,
                                            text: t(e.text),
                                        }))}
                                        placeholder={t('view.PurchaseOrders.Type')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                    <TypeaheadAsync
                                        dataId={createDataId(dataId, 'currency')}
                                        value={formik.values?.Currency}
                                        searchOnFocus
                                        toggleVisible
                                        onChange={(currency: TypeaheadItem<Currency>) => {
                                            formik.setFieldValue(CurrencyField, {
                                                text: `${currency.value.Code} - ${getCurrencyName(currency.value.Code)}`,
                                                value: currency.value.Code,
                                            });
                                            formik.setFieldTouched(CurrencyField, true);
                                            setIsFormDirty(true);
                                        }}
                                        inputProps={{ label: t('component.additionalInfo.currency'), type: TextInputType.COMPACT }}
                                        loadData={getCurrenciesByName}
                                        itemToText={(c: Currency) => `${c?.Code} - ${c?.Description}`}
                                        placeholder={t('component.additionalInfo.currency')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                </div>
                            </div>
                            <div className="invoice-header__fields edit">
                                <ul>
                                    <TextInput
                                        dataId={createDataId(dataId, 'supplierRegNumber')}
                                        type={TextInputType.COMPACT}
                                        value={formik.values.Supplier?.value?.RegistrationCode}
                                        disabled={true}
                                        overlapText={true}
                                        label={t('component.additionalInfo.supplierRegNumber')}
                                        placeholder={t('component.additionalInfo.supplierRegNumber')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                    <TextInput
                                        dataId={createDataId(dataId, 'supplierErpCode')}
                                        type={TextInputType.COMPACT}
                                        value={formik.values.Supplier?.value?.Code}
                                        disabled={true}
                                        overlapText={true}
                                        label={t('component.additionalInfo.supplierErpCode')}
                                        placeholder={t('component.additionalInfo.supplierErpCode')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                    <FormikField
                                        component={TypeaheadAsyncField}
                                        dataId={createDataId(dataId, 'beneficiary')}
                                        name={BeneficiaryField}
                                        searchOnFocus
                                        inputProps={{ label: t('component.additionalInfo.beneficiary'), type: TextInputType.COMPACT }}
                                        loadData={getBeneficiaryByName}
                                        onChange={() => setIsFormDirty(true)}
                                        itemToText={(s: SupplierDTO) => s?.Name}
                                        placeholder={t('component.additionalInfo.beneficiary')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                </ul>
                                <ul>
                                    <FormikField
                                        component={TextInputField}
                                        dataId={createDataId(dataId, 'referenceNumber')}
                                        name={ReferenceNumberField}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.referenceNumber')}
                                        placeholder={t('component.additionalInfo.referenceNumber')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                    <FormikField
                                        component={TextInputField}
                                        dataId={createDataId(dataId, 'payToAccount')}
                                        name={PayToAccountField}
                                        maxLength={35}
                                        validCharacters={ALPHANUMERIC_EXTENDED_REGEXP}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.payToAccount')}
                                        placeholder={t('component.additionalInfo.payToAccount')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                    <FormikField
                                        component={TextInputField}
                                        dataId={createDataId(dataId, 'contractNumber')}
                                        name={ContractNumberField}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.ContractNumber')}
                                        placeholder={t('component.additionalInfo.ContractNumber')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                </ul>
                                <ul>
                                    <FormikField
                                        component={CalendarDatePickerField}
                                        dataId={createDataId(dataId, 'invoiceDate')}
                                        name={InvoiceDateField}
                                        inputProps={assign({
                                            icon: ICONS.CALENDAR_24,
                                            isTooltipError: true,
                                            type: TextInputType.DATE_PICKER,
                                        })}
                                        label={t('component.additionalInfo.buyerContact.invoiceDate')}
                                        placeholder={t('component.additionalInfo.buyerContact.invoiceDate')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                    <FormikField
                                        component={CalendarDatePickerField}
                                        dataId={createDataId(dataId, 'dueDate')}
                                        name={DueDateField}
                                        inputProps={assign({
                                            icon: ICONS.CALENDAR_24,
                                            isTooltipError: true,
                                            type: TextInputType.DATE_PICKER,
                                        })}
                                        label={t('component.additionalInfo.buyerContact.dueDate')}
                                        placeholder={t('component.additionalInfo.buyerContact.dueDate')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                    <FormikField
                                        component={CalendarDatePickerField}
                                        dataId={createDataId(dataId, 'accountingDate')}
                                        name={AccountingDateField}
                                        inputProps={assign({
                                            icon: ICONS.CALENDAR_24,
                                            isTooltipError: true,
                                            type: TextInputType.DATE_PICKER,
                                        })}
                                        label={t('component.additionalInfo.buyerContact.accountingDate')}
                                        placeholder={t('component.additionalInfo.buyerContact.accountingDate')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                    {!!invoice?.InvoicePaidDateTime && (
                                        <PropertyListItem dataId={dataId} label={t('component.additionalInfo.invoicePaidDateTime')} value={formatDate(invoice.InvoicePaidDateTime)} />
                                    )}
                                </ul>
                                <ul>
                                    <FormikField
                                        component={MonetaryPrecisionScalesField}
                                        dataId={createDataId(dataId, 'sumWithoutVat')}
                                        name={SumWithoutVatField}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.sumWithoutVat')}
                                        placeholder={t('component.additionalInfo.sumWithoutVat')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                    <FormikField
                                        component={MonetaryPrecisionScalesField}
                                        dataId={createDataId(dataId, 'vatSum')}
                                        name={VatField}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.VAT')}
                                        placeholder={t('component.additionalInfo.VAT')}
                                        wrapperClass="fixed-height fixed-width"
                                    />
                                    <FormikField
                                        component={MonetaryPrecisionScalesField}
                                        dataId={createDataId(dataId, 'totalSum')}
                                        name={TotalAmountWithVatField}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.TotalAmountWithVat')}
                                        placeholder={t('component.additionalInfo.TotalAmountWithVat')}
                                        wrapperClass="fixed-height fixed-width"
                                        decimalScale={2}
                                    />
                                </ul>
                            </div>
                        </Form>
                    </WithTranslateFormErrors>
                );
            }}
        </Formik>
    );
};

export default BasicInfoEditMode;
